import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import Intro from "../components/intro"
import Description from "../components/description"
import Involvement from "../components/involvement"
import Supporters from "../components/supporters"

const IndexPage = ({ data }) => {

  const title = data.markdownRemark.frontmatter.title;
  const heroContent = data.markdownRemark.frontmatter.hero;
  const introContent = data.markdownRemark.frontmatter.intro;
  const descriptionContent = data.markdownRemark.frontmatter.description;
  const involvementContent = data.markdownRemark.frontmatter.involvement;
  const supportersContent = data.markdownRemark.frontmatter.supporters;

  return (
    <Layout classes="home" hiddenLogo="true">
      <Seo title={title} />
      <Intro heroContent={heroContent} introContent={introContent} />
      <Description descriptionContent={descriptionContent} />
      <Involvement involvementContent={involvementContent} />
      <Supporters supportersContent={supportersContent} />
    </Layout>
  )
}

export const query = graphql`{
  markdownRemark(frontmatter: {template_key: {eq: "home"}}) {
    frontmatter {
      title
      hero {
        small_title
        title
        caption
      }
      intro {
        title
        subtitle
        text
      }
      description {
        title
        text
      }
      involvement {
        title
        title_one
        link_one
        title_two
        link_two
      }
      supporters {
        title
        text
        title_one
        text_one
        link_one
        title_two
        text_two
        link_two
        title_three
        text_three
        link_three
      }
    }
  }
}
`

export default IndexPage
