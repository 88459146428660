import React from "react"
import { Link } from "gatsby"
import { Fade } from "react-awesome-reveal";

const Involvement = ({ involvementContent }) => {

  return (
    <section className="involvement">
      <div className="container text-center">
        <div className="row">
          <div className="col col-full">
            <Fade triggerOnce>
              <h3>{involvementContent.title}</h3>
            </Fade>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col col-one-half">
            <Link to={involvementContent.link_one} className="link-box">
              <Fade triggerOnce>
                <h4>{involvementContent.title_one}</h4>
              </Fade>
            </Link>
          </div>
          <div className="col col-one-half">
            <Link to={involvementContent.link_two} className="link-box">
              <Fade triggerOnce>
                <h4>{involvementContent.title_two}</h4>
              </Fade>
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Involvement
