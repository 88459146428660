import React from "react"
import { Fade } from "react-awesome-reveal";
import Logo from "./logo"

const Intro = ({ heroContent, introContent }) => {
  return (
    <>
      <section className="hero">
        <div className="container">
          <Fade triggerOnce>
            <div className="logo">
              <Logo />
            </div>
            <h2>{heroContent.title}</h2>
          </Fade>
        </div>
      </section>
      <section className="text-header">
        <div className="container">
          <Fade triggerOnce>
            <h1>{introContent.title}</h1>
            <p>{introContent.subtitle}</p>
          </Fade>
        </div>
      </section>
      <div className="article-content">
        <div className="container">
          <Fade triggerOnce>
            <p className="lead">{introContent.text}</p>
          </Fade>
        </div>
      </div>
    </>
  )
}

export default Intro
