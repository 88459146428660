import React from "react"
import remark from 'remark'
import remarkHTML from 'remark-html'
import { Fade } from "react-awesome-reveal";
import WarpIcon from "../components/warpIcon"

const Description = ({ descriptionContent }) => {
  const toHTML = value => remark().use(remarkHTML).processSync(value).toString();

  return (
    <section className="col-text">
      <WarpIcon />
      <Fade triggerOnce>
        <div className="container">
          <div className="row">
            <div className="col">
              <h3>{descriptionContent.title}</h3>
            </div>
            <div className="col">
            <section dangerouslySetInnerHTML={{ __html: toHTML(descriptionContent.text) }} />
            </div>
          </div>
        </div>
      </Fade>
    </section>
  )
}

export default Description
